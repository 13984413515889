// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { usePost } from "../../../../../hooks/useGet/index.ts";
import {
  Input,
  Select,
  DatePickerField,
} from "../../../../../../_metronic/_partials/controls";
import { AppContext } from "../../../../../providers/App";
import { UserContext } from "../../../../../providers/User";
import { useGet } from "../../../../../hooks/useGet/index.ts";

// Validation schema
const SectionEditSchema = Yup.object().shape({
  
});

export function SectionEditForm({  
  customer,
  actionsLoading,
  onHide,
}) {
  const { axios } = useContext(AppContext);
  const { getToken } = useContext(UserContext);
  const { data } = useGet("cardTypes/", []);  
  const datas  = useGet("fieldTypes/", []);  
  const cardtypes = data;
  const fieldtypes = datas.data;  
  console.log(fieldtypes);  
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{            
            cardtype: "",          
            fields: [],                        
        }}        
        validationSchema={SectionEditSchema}
        onSubmit={ async (values) =>  {
          console.log(values);
          try {
            const response = await axios.post(`/api/v1/cardtypes/${values.cardtype}`, values, {"headers": {"authorization": getToken()}});            
            if (response.data.error) {
              throw new Error(response.data.msg);
            }            
            console.log(response);
            onHide();
          } catch (error) {
            console.log(error.message);
          }
        }}
      >
        {({ handleSubmit, setFieldValue, handleChange }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>                
              )}
              <Form className="form form-label-right">                
                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-lg-12">                                        
                    <Select name="cardtype" label="Tipo">
                      {cardtypes.map((item, index) => (
                        <>
                        <option value={item.id} >{item.type}</option>
                        </>
                      ))}
                    </Select>
                  </div>
                  <div className="col-lg-12">
                    <div className='form-group row'>
                      {fieldtypes.map((data, index) => (
                        <div
                          key={index}
                          className='col-lg-12 my-3'
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          
                          <Field
                            key={data.id}
                            style={{
                              height: "20px",
                              width: "20px",
                              display: "inline-block",
                              marginLeft: "10px",
                            }}
                            component={Input}
                            type='checkbox'
                            name={`fields[${index}].enabled`}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue(`fields[${index}].value`, data);
                            }}
                            label={data.fieldKey}
                          />
                          <Field
                            key={data.id}
                            style={{
                              height: "20px",
                              width: "20px",
                              display: "inline-block",
                              marginLeft: "10px",
                            }}
                            component={Input}
                            type='checkbox'
                            name={`fields[${index}].important`}                            
                            label={"Important"}
                          />
                          <Field
                            type='text'
                            name={`fields[${index}].value`}
                            component={Input}
                            hidden
                          />                                                    
                          
                          
                        </div>
                      ))}
                    </div>  
                  </div>                             
                </div>                                
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
