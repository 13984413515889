import React, { useEffect } from "react";
import CardHeader from "./Header";
import CardContent from "./Content";
import CardFooter from "./Footer";
import "./styles.css";

export const Card = ({ form, avatar, bg, baseForm }) => {
  document.documentElement.style.setProperty(
    "--colorprimary",
    form.primary_color
  );
  let buttons = [];
  let icons = [];
  for (const key in form) {
    if (Array.isArray(form[key]) && key !== "categories") {
      const array = form[key];
      array.forEach((element, index) => {
        if (baseForm[key][index].important) {
          buttons.push({ ...baseForm[key][index], ...element });
        } else {
          icons.push({ ...baseForm[key][index], ...element });
        }
      });
    }
  }

  return (
    <div className="digiwrap">
      <div className='digitalcard empresarial desktop location'>
        <CardHeader
          description={form.description}
          avatar={avatar}
          name={form.name}
          slogan={form.slogan}
        />
        <CardContent
          icons={icons}
          buttons={buttons}
          name={form.name}
          bg={bg}
          description={form.description}
          avatar={avatar}
        />
        <CardFooter direction={form.direction} />
      </div>
    </div>
  );
};
