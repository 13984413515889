import React from "react";

export default ({avatar, name, slogan}) => {
    return (
        <div className="head patroci">	
            <img src={avatar} alt={name} className="logoempresa" />
            <div className="namecard">
                <h1>{name}</h1>
                <p>{slogan}</p>
            </div>
        </div>
    );
};
