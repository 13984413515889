import React, { Fragment } from "react";
import SVG from "react-inlinesvg";
import { ENDPOINT } from "../../../endpoint";

export default ({ direction }) => {
    if (direction.address === "") {
        return null;
    }
    return (
        <Fragment>
            <div className='foot'>
                <a
                    href='https://www.google.com.mx/maps/preview'
                    target='_blank'
                    className='linkmpapa'
                >
                    <div className='getloc'>
                        <SVG src={`${ENDPOINT}/images/icons/location.svg`} />
                        <span>¿Cómo llegar?</span>
                    </div>
                    <address>
                        {direction.address}, {direction.cp}. {direction.city},{" "}
                        {direction.country}.
                    </address>
                </a>
            </div>
        </Fragment>
    );
};
