import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import {
    FileLibraryListItem,
    ReactMediaLibrary,
    FileMeta,
} from "react-media-library";
import { ENDPOINT } from "../../../../../endpoint";
import { UserContext } from "../../../../providers/User";

interface Props {
    setParentItem?: (item: any) => void;
    title?: string;
}

const ReactMediaLibraryWrapper: React.FC<Props> = ({
    setParentItem,
    title,
}) => {
    const [display, setDisplay] = useState<boolean>(false);
    const [fileLibraryList, setFileLibraryList] = useState<
        FileLibraryListItem[]
    >([]);

    async function getData() {
        const newFileList = await getNewFileList();
        setFileLibraryList(newFileList);
    }
    async function deleteItem(id: any) {
        const result = await Axios.delete(`${ENDPOINT}/api/v1/files/${id}`, {
            headers: { authorization: getToken() },
        });
        return result;
    }

    const { getToken } = useContext(UserContext);

    async function fileUpload(file: any): Promise<any> {
        //@ts-ignore
        const form = new FormData();
        form.append("file", file);
        const response = await Axios.post(`${ENDPOINT}/api/v1/files`, form, {
            headers: { authorization: getToken() },
        });
        return response;
    }

    async function getNewFileList() {
        const response = await Axios.get(
            `${ENDPOINT}/api/v1/files/`,
            { headers: { authorization: getToken() } }
        );
        console.log(response);
        return response.data;
    }

    async function uploadCallback(file: any): Promise<boolean> {
        // TODO Upload file to backend APIs
        const result = await fileUpload(file);
        if (result.data.success) {
            const newFileList = await getNewFileList();
            setFileLibraryList(newFileList);
            return true;
        } else {
            return false;
        }
    }

    function setItem(item: any) {
        if (setParentItem) {
            setParentItem(item);
        }
    }

    function selectCallback(item: FileLibraryListItem) {
        setDisplay(false);

        setItem(item);
    }

    async function deleteCallback(item: FileLibraryListItem) {
        // TODO Delete file from backend service
        const result = await deleteItem(item.id);
        console.log(result);
        if (result.status == 200) {
            // Deleted file
            // TODO Reacquire file list from database
            getData();
        }
    }

    return (
        <React.Fragment>
            <button
                type='button'
                onClick={() => {
                    setDisplay(true);
                    getData();
                }}
                className='btn btn-light'
            >
                <i className='fa fa-upload'></i>
                {title}
            </button>
            <ReactMediaLibrary
                modalTitle='Librería Mulimedia'
                show={display}
                onHide={() => setDisplay(false)}
                fileUploadCallback={uploadCallback}
                fileLibraryList={fileLibraryList}
                fileSelectCallback={selectCallback}
                fileDeleteCallback={deleteCallback}
            />
        </React.Fragment>
    );
};

export default ReactMediaLibraryWrapper;
